<template>
    <v-container class="mb-12 pa-0">

        <v-tabs v-model="tab" grow>
            <v-tab>
                <v-icon left>
                    mdi-calendar-month-outline
                </v-icon>
                Reservas
            </v-tab>

            <v-tab v-if="evaluations.length > 0">

                <v-badge small :color="checkIfEvaluated" :content="evaluations.length" :value="evaluations.length"
                    overlap left id="badgeEval">
                    <v-icon left>
                        mdi-account-edit-outline
                    </v-icon>
                    Evaluaciones

                </v-badge>
            </v-tab>


            <v-tab-item>
                <v-row no-gutters>
                    <v-col cols="12">
                        <!-- if in the claims the type is admin or super user showa v-menu with a v datepicker to change the actual selected date -->
                        <v-menu offset-y
                            v-if="($store.state.Auth.token.claims.type == 'admin' || $store.state.Auth.token.claims.type == 'superuser')">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field hide-details="" dense v-model="date" label="Seleccionar fecha" readonly
                                    v-on="on" filled v-bind="attrs"></v-text-field>
                            </template>
                            <v-date-picker :max="maxDate" v-model="date" @input="fetchSchedules"
                                color="primary"></v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="12" v-if="loading">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col><v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col> <v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col> <v-col cols="6">
                                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                            </v-col>
                        </v-row>

                    </v-col>




                    <v-col cols="6" md="3" class="pa-2 pl-2 pr-2" v-for="schedule in schedules"
                        :key="schedule.startDate">


                        <v-card class="rounded fill-height" @click="showSchedule(schedule)" rounded="0"
                            :color="getScheduleColor(schedule)">
                            <v-card-title class="text-h6">

                                {{ formatToHourMinutes(schedule.startDate)
                                    + 'hs' }}

                                <v-spacer></v-spacer>

                                <v-icon left>
                                    mdi-account-multiple
                                </v-icon> {{ schedule.users.length }}



                            </v-card-title>
                            <v-card-text>
                                {{ getScheduleStatus(schedule) == 'ongoing' ? 'EN CURSO' : scheduleText(schedule) }}


                            </v-card-text>


                        </v-card>
                    </v-col>

                    <v-col cols="12" v-if="!loading && schedules.length == 0">
                        <v-alert color="primary darken-3" type="info" class="ma-4">
                            No hay horarios disponibles. <br> Estamos cerrados.
                        </v-alert>
                    </v-col>



                </v-row>





            </v-tab-item>

            <v-tab-item v-if="evaluations.length > 0">
                <v-card outlined class="elevation-0 ma-2" v-for="evaluation in evaluations" :key="evaluation.doc_id"
                    @click="setEvaluation(evaluation)">

                    <v-card-title class=" mt-2 py-0 text-center">
                        <v-icon left>
                            mdi-clock-outline
                        </v-icon>
                        {{ getHHMM(evaluation) }}hs
                        <v-spacer></v-spacer>
                        <v-chip outlined :color="evaluation.evaluatedBy ? 'success' : 'error'">

                            {{ evaluation.evaluatedBy ? 'Completada' : 'Pendiente' }}
                        </v-chip>


                    </v-card-title>
                    <v-card-text>

                    </v-card-text>


                    <v-card-actions class="pt-0 pl-4">

                        <v-icon left>
                            mdi-account-outline
                        </v-icon>
                        {{ evaluation.displayName }}

                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded @click="setEvaluation(evaluation)">
                            <v-icon right>
                                mdi-pencil-outline
                            </v-icon>
                            Evaluar
                        </v-btn>
                    </v-card-actions>

                </v-card>


                <v-dialog v-model="dialogs.evaluation" max-width="500px" fullscreen persistent>
                    <v-card class="rounded-0">
                        <v-card-title>
                            <span class="text-h5">Evaluación
                            </span> <v-spacer></v-spacer>
                            <v-btn icon @click="dialogs.evaluation = false;">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="evaluationForm">

                                <Preferences ref="evaluationPreferences" :isCoachView="true" :isEvaluationView="true"
                                    v-if="selectedUser && dialogs.evaluation" :user="selectedUser"
                                    :key="selectedUser.id + 'Preferences'" />

                                <h3 class="text-h6 my-4">Datos Base</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.altura" label="Altura" hint="Opcional" filled
                                            rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.peso" label="Peso" hint="Opcional" filled
                                            rounded></v-text-field>
                                    </v-col>
                                </v-row>



                                <v-text-field v-model="evaluation.nivelActividadFisica"
                                    label="Nivel de actividad física" filled rounded></v-text-field>
                                <v-textarea v-model="evaluation.objetivosCortoPlazo" label="Objetivos a corto plazo"
                                    filled rounded></v-textarea>
                                <v-textarea v-model="evaluation.objetivosLargoPlazo" label="Objetivos a largo plazo"
                                    filled rounded></v-textarea>

                                <h3 class="text-h6 mb-4">Evaluación de Movimiento - GLOBAL</h3>
                                <v-text-field v-model="evaluation.extension" label="Extensión" filled
                                    rounded></v-text-field>
                                <v-text-field v-model="evaluation.flexion" label="Flexión" filled
                                    rounded></v-text-field>
                                <v-text-field v-model="evaluation.rotacion" label="Rotacion" filled
                                    rounded></v-text-field>
                                <v-text-field v-model="evaluation.izquierdaGlobal" label="Izquierda" filled
                                    rounded></v-text-field>
                                <v-text-field v-model="evaluation.derechaGlobal" label="Derecha" filled
                                    rounded></v-text-field>
                                <v-text-field v-model="evaluation.sentadillaOH" label="Sentadilla OH" filled
                                    rounded></v-text-field>
                                <v-textarea v-model="evaluation.observacionesGlobal" label="Observaciones" filled
                                    rounded></v-textarea>

                                <h3 class="text-h6 mb-4">Analíticas</h3>
                                <v-text-field v-model="evaluation.respiracionDiafragmatica"
                                    label="Respiración diafragmática" filled rounded></v-text-field>

                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.capacidadTotal" label="Capacidad total" filled
                                            rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.capacidadResidual" label="Capacidad residual"
                                            filled rounded></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.scSuperior" label="SC superior" filled
                                            rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.scInferior" label="SC inferior" filled
                                            rounded></v-text-field>
                                    </v-col>
                                </v-row>



                                <v-text-field v-model="evaluation.sobrepronacion" label="Sobrepronacion" filled
                                    rounded></v-text-field>

                                <h3 class="mb-2 text-center">Movilidad cadera pierna recta</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.movilidadCaderaPiernaRectaIzquierda"
                                            label="Izquierda" filled rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.movilidadCaderaPiernaRectaDerecha"
                                            label="Derecha" filled rounded></v-text-field>
                                    </v-col>
                                </v-row>

                                <h3 class="mb-2 text-center">Test Thomas</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.testThomasIzquierda" label="Izquierda" filled
                                            rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.testThomasDerecha" label="Derecha" filled
                                            rounded></v-text-field>
                                    </v-col>
                                </v-row>

                                <h3 class="mb-2 text-center">Activación glúteo</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.activacionGluteoIzquierda" label="Izquierda"
                                            filled rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.activacionGluteoDerecha" label="Derecha"
                                            filled rounded></v-text-field>
                                    </v-col>
                                </v-row>

                                <h3 class="mb-2 text-center">Rotacion interna</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.rotacionInternaIzquierda" label="Izquierda"
                                            filled rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.rotacionInternaDerecha" label="Derecha" filled
                                            rounded></v-text-field>
                                    </v-col>
                                </v-row>

                                <h3 class="mb-2 text-center">Hombros</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.hombrosIzquierda" label="Izquierda" filled
                                            rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.hombrosDerecha" label="Derecha" filled
                                            rounded></v-text-field>
                                    </v-col>
                                </v-row>

                                <h3 class="mb-2 text-center">Rotacion dorsal</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.rotacionDorsalIzquierda" label="Izquierda"
                                            filled rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.rotacionDorsalDerecha" label="Derecha" filled
                                            rounded></v-text-field>
                                    </v-col>
                                </v-row>

                                <h3 class="mb-2 text-center">Dorsiflexion tobillo</h3>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.dorsiflexionTobilloIzquierda"
                                            label="Izquierda" filled rounded></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="evaluation.dorsiflexionTobilloDerecha" label="Derecha"
                                            filled rounded></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-textarea v-model="evaluation.resumen" label="Resumen" filled rounded></v-textarea>
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="pb-4">
                            <v-btn color="primary" :disabled="loading" text
                                @click="dialogs.evaluation = false">Volver</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="success" large :loading="loading" @click="submitEvaluation">
                                <v-icon left>
                                    mdi-check
                                </v-icon>
                                {{ evaluation.evaluatedBy ? 'Actualizar' : 'Guardar' }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-tab-item>

        </v-tabs>


        <!-- Users List Modal -->
        <v-bottom-sheet v-model="dialog" max-width="600px">
            <v-card v-if="selectedSchedule">

                <v-toolbar flat class="pl-4 text-h6 mb-6">
                      Reservas {{ formatToHourMinutes(selectedSchedule.startDate)
                        }}
                          <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>


                <v-card-text class="mt-2 pb-2">

                    <v-row v-if="loadingDisplayNames" no-gutters>
                        <v-col cols="12">
                            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                        </v-col>
                    </v-row>

                    <v-row v-if="selectedSchedule.users.length == 0">
                        <v-col cols="12">
                            <v-alert color="primary darken-3">
                                No hay reservas para este horario
                            </v-alert>
                        </v-col>
                    </v-row>


                    <v-row v-if="!loadingDisplayNames && selectedSchedule.users.length > 0" no-gutters>
                        <v-col cols="12" md="6">
                            <v-list class="pa-0" style="max-height:55vh; overflow-y: scroll;" rounded dense>
                                <v-list-item  v-for="user in selectedSchedule.users" :key="user.id"
                                    v-if="user.checkedIn" class="pa-0" @click="openUserImage(user)">
                                    <v-list-item-content class="pa-0">
                                        <v-list-item-title>
                                             <v-chip>
                                            <v-avatar left v-if="user.workoutsCompleted" color="success" text size="20"
                                                style="color:#FFF">
                                                {{
                                                    user.workoutsCompleted
                                                }}
                                            </v-avatar>


                                            <template v-if="userBirthDateCache[user.id]">
                                                <v-icon color="yellow lighten-3">
                                                    mdi-cake-variant
                                                </v-icon>
                                                {{ userBirthDateCache[user.id] }} -
                                            </template>

                                            <template v-if="injuryCache[user.id]">
                                                <v-icon left :color="injuryCache[user.id] == 1 ? 'red lighten-3' : 'green lighten-3'
                                                    ">
                                                    mdi-medical-bag
                                                </v-icon>
                                            </template>

                                            {{ user.displayName ? getFormattedDisplayName(user.displayName) : user.id }}


                                            <v-spacer></v-spacer>

                                            <template v-if="userCache[user.id] && userCache[user.id].mainWorkout && userCache[user.id].mainWorkout.plan_id">
                                                <v-icon>
                                                    mdi-dumbbell
                                                </v-icon>
                                           
                                            </template>

                                        </v-chip>



                                        </v-list-item-title>
                                    
                                       

                                    </v-list-item-content>

                                </v-list-item>

                            </v-list>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-list class="pa-0" style="max-height:55vh; overflow-y: scroll;">
                                <v-list-item dense  v-for="user in selectedSchedule.users"
                                    :key="user.id" class="pa-0" v-if="!user.checkedIn">
                                    <v-list-item-content class="pt-0 ">
                                        <v-chip @click="openSheet(user)">
                                            <v-icon left>
                                                mdi-checkbox-blank-circle-outline
                                            </v-icon>

                                            <template v-if="userBirthDateCache[user.id]">
                                                <v-icon color="yellow lighten-3">
                                                    mdi-cake-variant
                                                </v-icon>
                                                {{ userBirthDateCache[user.id] }} -
                                            </template>

                                            <template v-if="injuryCache[user.id]">
                                                <v-icon left :color="injuryCache[user.id] == 1 ? 'red lighten-3' : 'green lighten-3'
                                                    ">
                                                    mdi-medical-bag
                                                </v-icon>
                                            </template>

                                            {{ user.displayName ? getFormattedDisplayName(user.displayName) : user.id }}

                                           
                                        </v-chip>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>

                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn large rounded color="primary darken-1" @click="openIdModal" class="mb-4"
                        v-if="!disableNoActualDay">
                        <v-icon left>
                            mdi-plus
                        </v-icon>
                        Agregar sin reserva
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet v-model="sheetAction" max-width="600px">
            <v-card>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="sheetAction = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                </v-card-title>

                <v-card-text class=" text-center" v-if="selectedUser">

                    <UserImage :user="selectedUser"></UserImage>
                    <span class="text-h6">{{ selectedUser.displayName }}</span>
                </v-card-text>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-btn color="success" x-large block @click="checkAssistance" :loading="loadingAssistance"
                                v-if="!disableNoActualDay">
                                <v-icon left>mdi-check</v-icon>
                                Marcar como asistido</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>

        <QRreader :selectedSchedule="selectedSchedule" :usingComponentAsAnAPI="true" ref="qrreader"
            @success="checkAssistanceUser"></QRreader>


        <v-dialog v-model="userImage"   persistent max-width="500">



            <v-card v-if="selectedUser" class="rounded-0 ">
                <v-toolbar flat dense class="pl-2">
                       
                        
                        <v-toolbar-title>

                             
                            {{ selectedUser.displayName }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                      <v-btn icon @click="userImage = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                </v-toolbar>

                <v-card-text class=" text-center pa-0">

                    <v-tabs grow>
                       

                        <v-tab>
                            Perfil
                        </v-tab>
                        <v-tab>

                            Objetivos
                        </v-tab>

                          <v-tab >
                            Plan
                        </v-tab>



                        <v-tab-item class="pa-2">
                            <UserImage class="my-4" :user="selectedUser"></UserImage>
                            <span class="text-h6"> {{ selectedUser.displayName }}</span>



                            <PlanProgress :userObject="selectedUser" :key='selectedUser.id'></PlanProgress>

                            <v-btn block color="primary darken-3" class="rounded-lg mt-4" rounded large
                                @click="openProgress(selectedUser)">
                                <v-icon left class="mr-4">
                                    mdi-finance
                                </v-icon>
                                Ver progreso
                            </v-btn>
                        </v-tab-item>

                        <v-tab-item class="pa-2"> 
                            <Preferences :isCoachView="true" :user='userCache[selectedUser.id]'
                                :key="selectedUser.id + 'Preferences'" />
                        </v-tab-item>


                        <v-tab-item class="pa-0">
                            <PlanView v-if="userImage" :key="selectedUser.id" :UserObject="userCache[selectedUser.id]"></PlanView>
                        </v-tab-item>


                    </v-tabs>

                </v-card-text>



            </v-card>
        </v-dialog>
        <v-dialog v-model="userProgress" fullscreen>
            <v-card v-if="selectedUser && selectedUser.displayName">
                <v-card-title class="pa-1 pb-0">
                    <v-btn icon @click="userProgress = false; selectedUser = null; userImage = false;">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <UserImage :small="true" :user="selectedUser" class="mx-2"></UserImage>

                    {{ selectedUser.displayName }}
                </v-card-title>
                <v-card-text class="pa-0">
                    <Weights :user="selectedUser"></Weights>
                </v-card-text>
            </v-card>



        </v-dialog>
    </v-container>
</template>

<script>

import { collection, getDocs, getDoc, onSnapshot, query, where, orderBy, startAt, endAt, getFirestore, setDoc, doc, addDoc, updateDoc, Timestamp } from "firebase/firestore";

import moment from 'moment';

import QRreader from '@/components/QRreader.vue';

import UserImage from '@/components/profile/UserImage.vue';
import Preferences from '@/components/training/Preferences.vue';
const db = getFirestore();

// import Weights, PlanProgress components

import Weights from '@/components/Weights.vue';
import PlanProgress from '@/components/PlanProgress.vue';

import User from "@/models/user.js";
import { logAuditEvent } from '@/error/audit.js';
///        await logAuditEvent('update',this.$store.state.Auth.token.claims.user_id,`User ${this.user.id} re-enabled`)


import PlanView from '@/views/PlanView.vue';


export default {
    beforeDestroy() {
        if (this.unsubscribeScheduleListener) {
            this.unsubscribeScheduleListener();
        }
        if (this.unsubscribeUserListener) {
            this.unsubscribeUserListener();
        }
    },

    components: {
        QRreader, UserImage, Weights, PlanProgress, Preferences,PlanView
    },
    computed: {
        returnTodayFormatted() {
            return moment().locale('es').format('LL');
        },
        checkIfEvaluated() {
            // iterate all this.evaluations and check if all are evaluated or not
            let evaluated = true;
            this.evaluations.forEach(evaluation => {
                if (!evaluation.evaluatedBy) {
                    evaluated = false;
                }
            });

            return evaluated ? 'success' : 'error';
        }
    },
    data() {
        return {
            userProgress: false,
            tab: 0,
            userImage: false,
            schedules: [],
            selectedSchedule: null,
            dialog: false,
            loading: false,
            loadingDisplayNames: true,
            scheduleStatus: "", // New property to keep track of the schedule's status
            sheetAction: false,
            selectedUserId: null,
            selectedUser: null,
            loadingAssistance: false,
            // Other data properties
            unsubscribeScheduleListener: null,
            unsubscribeUserListener: null,
            userDisplayNameCache: {},
            userBirthDateCache: {},
            userWorkoutsCompletedCache: {},
            userPlanCache: {},
            //data for the second tab
            evaluations: [],
            dialogs: {
                evaluation: false
            },
            evaluation: {},
            selectedEvaluation: null,
            date: moment().format('YYYY-MM-DD'),
            disableNoActualDay: false,
            maxDate: moment().add(4, 'days').format('YYYY-MM-DD'),
            injuryCache: {},
            userCache: {}
        };
    },

    mounted() {
        this.$vuetify.goTo(0);

        this.fetchSchedules();

        this.fetchEvaluations();
    },
    methods: {
        formatToHourMinutes(date) {
            return moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
        },
        openSheet(user) {
            this.selectedUserId = null;
            this.selectedUser = null

            this.sheetAction = true;
            this.selectedUserId = user.id;
            this.selectedUser = user;
        },
        openUserImage(user) {
            this.selectedUser = null;
            this.selectedUser = user;
            this.userImage = true;
        },

        scheduleText(schedule) {
            let date = schedule.startDate;
            let dateMoment = moment(date, 'YYYY-MM-DD HH:mm:ss');
            let checkToday = moment().isSame(dateMoment, 'day');

            if (checkToday && moment().isAfter(dateMoment)) {
                return 'Hace ' + dateMoment.locale('es').fromNow(true);
            } else {
                return 'En ' + dateMoment.locale('es').fromNow(true);
            }
        },

        getScheduleColor(schedule) {
            const status = this.getScheduleStatus(schedule);

            let colorTheme = this.$store.state.isDark

            if (colorTheme) {
                switch (status) {
                    case "started":
                        return "primary darken-4"; // Color for schedules that have not started
                    case "ongoing":
                        return "primary darken-1"; // Color for ongoing schedules
                    case "finished":
                        return "grey darken-3"; // Color for finished schedules
                    default:
                        return "primary darken-3"; // Default color
                }
            } else {
                switch (status) {
                    case "started":
                        return "primary"; // Color for schedules that have not started
                    case "ongoing":
                        return "primary lighten-1"; // Color for ongoing schedules
                    case "finished":
                        return "grey lighten-3"; // Color for finished schedules
                    default:
                        return "primary lighten-3"; // Default color
                }
            }

        },

        getScheduleStatus(schedule) {
            let date = schedule.startDate;
            let dateMoment = moment(date, 'YYYY-MM-DD HH:mm:ss');
            let checkToday = moment().isSame(dateMoment, 'day');

            let dateWithDuration = moment(date, 'YYYY-MM-DD HH:mm:ss').add(schedule.duration, 'minutes');

            if (checkToday && moment().isAfter(dateWithDuration)) {
                return "finished"; // Schedule has already passed
            } else if (moment().isAfter(dateMoment) && moment().isBefore(dateWithDuration)) {
                return "ongoing";  // Schedule is today but hasn't passed yet
            } else {
                return "started";  // Schedule is in the future
            }
        },

        fetchSchedules() {
            //clear the cache
            this.userDisplayNameCache = {};
            this.userBirthDateCache = {};
            this.userWorkoutsCompletedCache = {};
            this.userPlanCache = {};
            this.userCache = {};

            this.loading = true;
            const today = this.date;

            let now = moment()
            let todayMoment = moment(today, 'YYYY-MM-DD');
            let checkToday = now.isSame(todayMoment, 'day');

            if (checkToday) {
                this.disableNoActualDay = false;
            } else {
                this.disableNoActualDay = true;
            }



            const schedulesRef = collection(db, `schedule/${today}/schedules`);

            this.unsubscribeScheduleListener = onSnapshot(schedulesRef, (snapshot) => {
                this.schedules = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                this.loading = false;
            });

            logAuditEvent('read', this.$store.state.Auth.token.claims.user_id, `Read schedules for ${today}`);
        },


           async getUserDisplayName(userId) {
    try {
        const userRef = doc(db, `users/${userId}`);
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
            return { displayName: userId + " Usuario no encontrado" };
        }

        const userData = userSnap.data();
        userData.id = userId;

        const birthDate = userData.birthDate;
        const birthDateMoment = moment(birthDate, 'YYYY-MM-DD');
        const age = moment().diff(birthDateMoment, 'years');

        const today = moment();
        if (today.date() === birthDateMoment.date() && today.month() === birthDateMoment.month()) {
            this.$set(this.userBirthDateCache, userId, age);
        }

        if (userData.injuries && userData.injuries.length > 0) {
            const hasActiveInjury = userData.injuries.some((injury) => !injury.recuperado);
            this.$set(this.injuryCache, userId, hasActiveInjury ? 1 : 2);
        }

        return userData;
    } catch (error) {
        console.error("Error fetching user display name:", error);
        return { displayName: userId + " Error al obtener datos del usuario" };
    }
},

showSelectedScheduleUsers() {
    if (!this.selectedSchedule || !this.selectedSchedule.id) return;

    const today = this.date;
    const scheduleDocRef = doc(db, `schedule/${today}/schedules`, this.selectedSchedule.id);

    if (this.unsubscribeUserListener) {
        this.unsubscribeUserListener(); // Clean up any previous listener
    }

    this.unsubscribeUserListener = onSnapshot(scheduleDocRef, async (docSnapshot) => {
        if (!docSnapshot.exists()) {
            console.log("No such document!");
            return;
        }

        const scheduleData = docSnapshot.data();
        if (scheduleData.users && Array.isArray(scheduleData.users)) {
            this.loadingDisplayNames = true;

            try {
                // Map over the users and resolve the promises properly using await
                const usersWithNames = await Promise.all(
                    scheduleData.users.map(async (user) => {
                        const userId = user.id;

                        if (!this.userDisplayNameCache[userId]) {
                            const fetchedUser = await this.getUserDisplayName(userId);
                            this.userDisplayNameCache[userId] = fetchedUser.displayName || "Unknown User";
                            this.userPlanCache[userId] = fetchedUser.plan;
                            this.userCache[userId] = fetchedUser
                        }

                        if (!this.userWorkoutsCompletedCache[userId] && user.checkedIn) {
                            const workoutsCompleted = await this.getWorkoutsCompleted(userId);
                            this.userWorkoutsCompletedCache[userId] = workoutsCompleted;
                        }

                        return {
                            ...user,
                            displayName: this.userDisplayNameCache[userId],
                            workoutsCompleted: this.userWorkoutsCompletedCache[userId]
                        };
                    })
                );

                // Sort users by checkedIn status and then by displayName
                usersWithNames.sort((a, b) => {
                    if (a.checkedIn && !b.checkedIn) return -1;
                    if (!a.checkedIn && b.checkedIn) return 1;
                    return a.displayName.localeCompare(b.displayName);
                });

                this.selectedSchedule.users = usersWithNames;
            } catch (error) {
                console.error("Error processing users:", error);
            } finally {
                this.loadingDisplayNames = false;
            }
        }
    });
},






        successCheckIn(userId) {
            const index = this.selectedSchedule.users.findIndex(user => user.id == userId);
            this.selectedSchedule.users[index].checkedIn = true;
        },

        async checkAssistanceUser(userNew) {
            return false;
            // add user to schedule users array if not already there (in case of adding user without reservation) 
            const userIndex = this.selectedSchedule.users.findIndex(user => user.id == userNew.id);
            if (userIndex == -1) {
                const userRef = doc(db, `users/${this.selectedUserId}`);
                const userSnap = await getDoc(userRef);
                const user = userSnap.data();
                this.selectedSchedule.users.push({
                    ...userNew,
                    checkedIn: true
                });
            }
            else {
                this.selectedSchedule.users[userIndex].checkedIn = true;
            }

            this.$forceUpdate();


        },

        async checkAssistance() {
            try {
                this.loadingAssistance = true;
                let result = await this.$refs.qrreader.loginWithIDAPI(this.selectedUserId);
                if (result) {
                    //this.successCheckIn(this.selectedUserId);
                    logAuditEvent('checkin', this.$store.state.Auth.token.claims.user_id, `User ${this.selectedUserId} checked in`);

                    this.sheetAction = false;
                }

            } catch (e) {

                logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Error checking in user ${this.selectedUserId} for schedule ${JSON.stringify(this.selectedSchedule)} error ${e}`);
                console.log(e);
            } finally {
                this.loadingAssistance = false;
            }
        },
        showSchedule(schedule) {
            this.selectedSchedule = schedule;
            this.loadingDisplayNames = true;

            // Start listening for real-time updates on users for the selected schedule
            this.showSelectedScheduleUsers();

            this.dialog = true;
        },
        openIdModal() {
            this.$refs.qrreader.focusCivilId();
        },

        async fetchEvaluations() {
            const evaluationsRef = collection(db, `evaluationsHistory`);

            const todayStart = moment().startOf('day').toDate();
            const todayEnd = moment().endOf('day').toDate();

            const q = query(evaluationsRef, where('date', '>=', todayStart), where('date', '<=', todayEnd), orderBy('date', 'desc'));

            const querySnapshot = await getDocs(q);

            this.evaluations = querySnapshot.docs.map(doc => ({ ...doc.data(), doc_id: doc.id }));

            //get the user display name
            this.evaluations.forEach(async (evaluation, index) => {
                const userRef = doc(db, `users/${evaluation.id}`);
                const userSnap = await getDoc(userRef);
                const user = userSnap.data();
                this.evaluations[index].displayName = user.displayName;
                this.evaluations[index].userData = { ...user };
            });


        },

        setEvaluation(evaluation) {
            this.selectedEvaluation = null;

            this.selectedEvaluation = evaluation;
            this.dialogs.evaluation = true;


            this.selectedUser = evaluation.userData;

            if (evaluation.evaluatedBy) {
                this.evaluation = evaluation.result;
            }
        },

        async submitEvaluation() {

            const evaluation = {
                ...this.evaluation,
                date: Timestamp.fromDate(moment().toDate()),
                evaluatedBy: this.$store.state.Auth.token.claims.name ? this.$store.state.Auth.token.claims.name : this.$store.state.Auth.token.claims.email
            };

            try {
                this.loading = true;

                // add to the user evaluations collection
                const db = getFirestore();
                delete evaluation['date']
                // update to the evaluations history collection inside result attribute
                await updateDoc(doc(db, "evaluationsHistory", this.selectedEvaluation.doc_id), {
                    result: evaluation,
                    evaluatedBy: this.$store.state.Auth.token.claims.name ? this.$store.state.Auth.token.claims.name : this.$store.state.Auth.token.claims.email,
                    evaluatedDate: Timestamp.fromDate(moment().toDate())
                });

                // update the local evaluations array
                const index = this.evaluations.findIndex(e => e.doc_id == this.selectedEvaluation.doc_id);
                this.evaluations[index].evaluatedBy = this.$store.state.Auth.token.claims.name ? this.$store.state.Auth.token.claims.name : this.$store.state.Auth.token.claims.email;
                this.evaluations[index].result = evaluation;

                this.dialogs.evaluation = false;
                this.selectedEvaluation = null;
                // show success notification
                this.$notify({
                    group: "feedback",
                    duration: 5000,
                    type: "success",
                    title: "Evaluación",
                    text: "Evaluación completada",
                });


                let resp = await this.$refs['evaluationPreferences'].updateUserData()

                await logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `Evaluation ${this.selectedEvaluation.doc_id} updated data ${JSON.stringify(evaluation)}`);

            } catch (e) {

                await logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Error updating evaluation ${this.selectedEvaluation.doc_id} data ${JSON.stringify(evaluation)} error ${e}`);
                
                this.$notify({
                    group: "feedback",
                    duration: 5000,
                    type: "error",
                    title: "Evaluación",
                    text: "Error al completar la evaluación",
                });
            } finally {
                this.loading = false;
            }
        },


        getHHMM(evaluation) {
            return moment(evaluation.date.toDate()).format('HH:mm');
        },

        openProgress(user) {
            this.userProgress = true;
        },

        getStartOfWeek() {
            const now = moment(this.date, 'YYYY-MM-DD').toDate();
            let day = now.getDay();
            const diff = (day === 0 ? -6 : 1); // if it's Sunday, subtract 6, otherwise 1
            const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day + diff);
            return startOfWeek;
        },
        getEndOfWeek() {
            const startOfWeek = this.getStartOfWeek();
            const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6);
            return endOfWeek;
        },

        async getWorkoutsCompleted(user) {

            const db = getFirestore()
            const workoutsRef = collection(db, `users/${user}/workouts`);
            const startOfWeek = this.getStartOfWeek();
            const endOfWeek = this.getEndOfWeek();

            const q = query(workoutsRef, where("weekStart", ">=", startOfWeek), where("weekStart", "<", endOfWeek));
            const workoutDocs = await getDocs(q);

            let workoutDoc = workoutDocs.docs[0]
            let workoutsCompleted = workoutDoc.data().day
            let workoutDates = workoutDoc.data().dates

            return workoutsCompleted
        },
        getFormattedDisplayName(displayName) {
            // this method must split the displayName with " " and return the first two words but before delete all the items that are empty strings
            let displayNameArray = displayName.split(" ").filter(item => item !== "");
            let formattedDisplayName = displayNameArray.slice(0, 3).join(" ");
            return formattedDisplayName;

        }

    }
};
</script>

<style scoped>
.v-chip {
    display: grid !important;
}
</style>